<template>
  <div class="TagCreation" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ajouter un nouveau tag</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Une erreur s'est produite">
              <div class="text-center">
                <p>Une erreur s'est produite,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="TagCreatedModal" id="TagCreatedModal" size="lg" hide-footer title="Tag créé">
              <div class="text-center">
                <p><strong>Votre tag a bien été créé ! </strong></p>

                <b-button class="mt-3" block pull variant="outline-dark" @click="$router.push('/tags')">
                  Retour à la liste des tags
                </b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="createTag()">
              <b-form-group id="create-tag-name-group" label="Nom du tag" label-for="create-tag-name-input" class="mandatory-input">
                <b-form-input
                  v-model="createTagName" type="text"
                  maxlength="250"
                  placeholder="Nom"
                  id="create-tag-username-input"
                  @input="$v.createTagName.$touch()"
                  :state="$v.createTagName.$dirty ? !$v.createTagName.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createTagName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
              </b-form-group>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidTag">
                  Créer votre tag
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APITag } from '@/api/APITag'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiTag = new APITag()

export default {
  name: 'TagCreation',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      createTagName: '',
      createTagInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidTag () {

      if (this.$v.createTagName.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.createTagInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    createTagName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
  },

  methods: {
    createTag () {
      this.createTagInProcess = true
      apiTag.createTag(
        this.token, this.createTagName)
      .then(() => {
        this.$refs.TagCreatedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagCreation createTag API Error : ' + String(error))
      })
      .finally(() => {
        this.createTagInProcess = false
      })

    },
  }
}
</script>
